import { Field, Hint, Label, Toggle } from '@zendeskgarden/react-forms';
import { Col } from '@zendeskgarden/react-grid';
import { ThemeProvider } from "@zendeskgarden/react-theming";
import React from 'react';
import Loading from '../../components/loading';
import { UserData } from '../../model/user.model';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from "../../services/auth.service";
import UserService from '../../services/user.service';
import TranslationService, { t } from '../../translations/translation.service';
import { theme } from "../../util/colorButton";
import './index.css';

class Settings extends React.Component <any> {
  state = {
    user: {} as UserData,
    emailChecked: false,
    phoneChecked: false,
    loading: false,
  }

  constructor(props: any) {
    super(props);
    this.updateSettings = this.updateSettings.bind(this);
    this.toggleEmailNotifications = this.toggleEmailNotifications.bind(this);
    this.togglePhoneNotifications = this.togglePhoneNotifications.bind(this);
  }

  toggleEmailNotifications() {
    this.setState((prevState: any) => ({
      emailChecked: !prevState.emailChecked
    }), () => {
      this.updateSettings();
    });
  }

  togglePhoneNotifications() {
    this.setState((prevState: any) => ({
      phoneChecked: !prevState.phoneChecked
    }), () => {
      this.updateSettings();
    });
  }

  async updateSettings() {
    delete this.state.user.password;

    if (!this.state.user.notificationsSettings || this.state.user.notificationsSettings.channels.length === 0) {
      let newNotificationsSettings = {
        isActive: true,
        channels: [
          {
            isActive: true,
            name: "E-mail",
          },
          {
            isActive: true,
            name: "Telefone",
          }
        ]
      }

      const response = await UserService.updateUser({...this.state.user, notificationsSettings: newNotificationsSettings});

      if (response.status !== 200) {
        AlertService.error(t("Opa! Houve um problema ao processar a sua requisição. Por favor, tente novamente."));
        return;
      }

      this.setState({
        emailChecked: true,
        phoneChecked: true
      })

      return;
    }

    const notificationsSettings = this.state.user.notificationsSettings;

    // Set isActive to true only if either email or phone notifications are enabled
    notificationsSettings.isActive = this.state.emailChecked || this.state.phoneChecked;
    
    notificationsSettings.channels.forEach(
      (channel: any) => {
        if (channel.name === "E-mail") channel.isActive = this.state.emailChecked
        if (channel.name === "Telefone") channel.isActive = this.state.phoneChecked
      }
    );
    
    this.setState({
      user: { ...this.state.user, notificationsSettings }
    }, async () => {
      const response = await UserService.updateUser(this.state.user);

      if (response.status !== 200) {
        AlertService.error(t("Opa! Houve um problema ao processar a sua requisição. Por favor, tente novamente."));
        return;
      }

      this.setState({ user: response.data, loading: false });
      AlertService.success(t("Configurações de comunicação atualizadas com sucesso."));
    });
  }

  componentDidMount() {
    const userId = this.props.match.params.id;
    
    AuthenticationService.getUser()._id !== userId
      ? this.props.history.push("/login")
      : this.setState({ loading: true });

    UserService.getUserWithOpportunities(userId).then((resp) =>
      this.setState({ user: resp.data.user, loading: false }, async () => {
        this.state.user.notificationsSettings.channels.forEach(
          (channel: any) => {
            if (channel.name === "E-mail")
              this.setState({ emailChecked: channel.isActive });
            if (channel.name === "Telefone")
              this.setState({ phoneChecked: channel.isActive });
          }
        );

        if (!this.state.user.notificationsSettings || this.state.user.notificationsSettings.channels.length === 0) {
          await this.updateSettings();
        }
      })
    );
  }

  render() {
    return (
      <div className="set-body">
        <ThemeProvider theme={theme as any}>
          <Loading show={this.state.loading} />
          <h4 className="set-title">{t("Configurações de Comunicação")}</h4>
          <hr
            style={{
              borderTop: '2px solid #707070',
            }}
          />
          <div className="mt-5">
            <Col md={8}>
              <Label isRegular>
                {t("TextoDescricaoConfiguracoesComunicacao")}
              </Label>
            </Col>
            <div className="mt-4">
              <Col className="mb-4">
                <Field>
                  <Toggle
                    checked={this.state.emailChecked} 
                    onChange={this.toggleEmailNotifications}
                  >
                    <Label
                      className="set-input"
                      isRegular
                    >
                      <b>{t("TextoNotificacaoEmail")}</b>
                    </Label>
                    <Hint>
                      {t("TextoDescricaoNotificacaoEmail")}
                    </Hint>
                  </Toggle>
                </Field>
              </Col>
              <Col className="mb-4">
                <Field>
                  <Toggle
                    checked={this.state.phoneChecked}
                    onChange={this.togglePhoneNotifications}
                  >
                    <Label
                      className="set-input"
                      isRegular
                    >
                      <b>{t("TextoNotificacaoTelefone")}</b>
                    </Label>
                    <Hint>
                      {t("TextoDescricaoNotificacaoTelefone")}
                    </Hint>
                  </Toggle>
                </Field>
              </Col>

            </div>
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

export default Settings;
